'use client';

import * as React from 'react';
import { Circle } from '@phosphor-icons/react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from './utils/cn';
import { useUuid } from './utils/use-uuid';

export interface RadioGroupProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {}

export const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-3', className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export interface RadioGroupItemProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    'label'
  > {
  label: string;
  error?: string;
}

export const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, label, error, ...props }, ref) => {
  const uuid = useUuid();

  return (
    <label
      className={cn(
        'flex cursor-pointer gap-2',
        props.disabled && 'cursor-not-allowed opacity-50'
      )}
    >
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'relative top-[2px] aspect-square h-5 w-5 shrink-0 rounded-full',
          'border border-ash',
          'outline-none focus-visible:outline-2 focus-visible:outline-ink',
          error && 'border-grapefruit',
          className
        )}
        aria-labelledby={uuid}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="relative flex items-center justify-center animate-in zoom-in-50">
          <Circle weight="fill" className="size-[11px] text-ink" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      <p
        aria-hidden
        className={cn('bits-text-body-1 text-ink', error && 'text-grapefruit')}
        id={uuid}
      >
        {label}
      </p>
    </label>
  );
});
RadioGroupItem.displayName = 'RadioGroupItem';
