import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { dropdownClasses } from '../checkbox-dropdown';
import { DatePicker } from '../date-picker';
import { AdvancedFilterGroup, AdvancedFilterObject } from './advanced-filters';

export const DateRangeFilter = ({
  filter,
  onFiltersChange,
  activeFilters,
  id,
}: {
  filter: AdvancedFilterGroup;
  activeFilters: AdvancedFilterObject;
  onFiltersChange: (filters: AdvancedFilterObject) => void;
  id: string;
}) => {
  const fromYear = 2023;
  const toYear = new Date().getFullYear();

  return (
    <div className="grid gap-2 p-1">
      {'presets' in filter && (
        <div>
          {filter.presets?.map(({ label, from, to, count }, index) => (
            <DropdownMenu.Item
              key={index}
              className={dropdownClasses.item}
              onSelect={() => {
                onFiltersChange({
                  ...activeFilters,
                  [id]: [
                    from ? new Date(from) : undefined,
                    to ? new Date(to) : undefined,
                  ],
                });
              }}
            >
              {label}
              {count !== undefined && (
                <div className="bits-text-button-2 ml-auto">{count}</div>
              )}
            </DropdownMenu.Item>
          ))}
        </div>
      )}

      <p>From date:</p>
      <DatePicker
        mode="single"
        captionLayout="dropdown"
        fromYear={fromYear}
        toDate={
          activeFilters[id]?.[1]
            ? new Date(activeFilters[id]?.[1] as Date)
            : undefined
        }
        selected={
          activeFilters[id]?.[0]
            ? new Date(activeFilters[id]?.[0] as Date)
            : undefined
        }
        onSelect={(date) => {
          onFiltersChange({
            ...activeFilters,
            [id]: [date, activeFilters[id]?.[1]],
          });
        }}
      />
      <p>To date:</p>
      <DatePicker
        mode="single"
        captionLayout="dropdown"
        toYear={toYear}
        selected={
          activeFilters[id]?.[1]
            ? new Date(activeFilters[id]?.[1] as string | Date)
            : undefined
        }
        fromDate={
          activeFilters[id]?.[0]
            ? new Date(
                new Date(activeFilters[id]?.[0] as string | Date).getTime() +
                  86400000
              )
            : undefined
        }
        onSelect={(date) => {
          onFiltersChange({
            ...activeFilters,
            [id]: [activeFilters[id]?.[0], date],
          });
        }}
      />
    </div>
  );
};
