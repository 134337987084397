import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  ArrowsClockwise,
  File,
  TrashSimple,
  UploadSimple,
} from '@phosphor-icons/react';

import { useControlledState, useUncontrolledState } from '../..';
import { SmallButton } from '../small-button';
import { SmallSpinner } from '../small-spinner';
import { cn } from '../utils/cn';
import { getFileInfo } from '../utils/get-file-info';

export interface FormFileInputProps {
  value?: string;
  description: string;
  maxSize?: number;
  accept?: string;
  error?: string;
  onChange?: (file: File | null) => void | Promise<unknown>;
  onRemove?: () => void;
}

/**
 * File input with preview, if value is provided
 * If the value is an image file, the image will be shown. Otherwise,
 * a file icon will show as a preview.
 */
export const FormFileInput = ({
  value,
  maxSize,
  description,
  accept,
  error,
  onChange,
  onRemove,
}: FormFileInputProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [fileError, setFileError] = useState<string | undefined>(error);
  const [preview, setPreview] = useControlledState<string | undefined>(value);
  const [fileValue, setFileValue] = useState<File | null>();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = async (event: {
    target: { files: FileList | File[] | null };
  }) => {
    if ('files' in event.target) {
      setFileError(undefined);
      const file = (event.target.files as File[])[0];

      if (file) {
        /**
         * Use a magic bytes library to get the file type.
         * Can have mixed results with SVGs as they are technically "XML" files.
         */
        const filetype = await getFileInfo(file);
        const ext =
          filetype?.typename?.toLowerCase() ||
          (file.name.split('.').pop()?.toLowerCase() as string);

        if (
          accept &&
          !accept
            ?.split(',')
            .map((acceptExt) => acceptExt.replace('.', ''))
            .includes(ext)
        ) {
          setFileError(`Invalid file type "${ext}"`);
          setFileValue(null);
          return;
        }

        setFileValue(file || null);

        // Set the preview for the url, it will only show if it's an image
        const previewUrl = URL.createObjectURL(file);
        setPreview(previewUrl);

        // If the file is too big, reset the input value
        if (maxSize && file.size > maxSize && fileInput.current) {
          fileInput.current.value = '';
          setFileError(
            'File exceeds the maximum size of ' + maxSize / 1024 / 1024 + ' MB'
          );
          return;
        }

        const potentialUploadPromise = onChange?.(file || null);

        if (potentialUploadPromise?.then) {
          setIsLoading(true);
          potentialUploadPromise.then(() => {
            setIsLoading(false);
          });
        }
      }
    }
  };
  // Trigger the file input prompt
  const triggerFileInput = () => {
    if (fileInput.current) fileInput.current.click();
  };

  // Remove the file, also set the input value to empty to allow it to be uploaded again
  const handleRemove = () => {
    setFileError(undefined);
    setFileValue(undefined);
    setPreview(undefined);
    if (fileInput.current) fileInput.current.value = '';
    onChange?.(null);
    onRemove?.();
  };

  // If the value is removed, reset the preview and file value
  useEffect(() => {
    if (!value) {
      setPreview(undefined);
      setFileValue(undefined);
    }
  }, [value]);

  const hasValue = value || fileValue;
  const fileName =
    fileValue?.name ||
    (preview !== undefined
      ? decodeURIComponent(preview).split('/').pop()
      : undefined);
  const fileSize = (fileValue?.size || 0) / 1024 / 1024;
  const isFileImage = ['jpg', 'jpeg', 'png'].includes(
    fileName?.split('.').pop()?.toLowerCase() || ''
  );

  const [isDragging, setIsDragging] = useState(false);
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFileError(undefined);
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files.length) {
      handleChange({ target: event.dataTransfer });
    }
  };

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-6 rounded-[10px] border-2 border-dashed border-fog p-8 transition-all',
        isLoading && 'pointer-events-none',
        fileError && 'border-grapefruit',
        isDragging &&
          'border-solid bg-tangerine-light/10 [&_*]:pointer-events-none'
      )}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(event) => event.preventDefault()}
      onDrop={handleDrop}
    >
      <input
        type="file"
        className="hidden"
        ref={fileInput}
        accept={accept}
        onChange={handleChange}
      />

      {!hasValue && (
        <SmallButton onClick={triggerFileInput}>
          <UploadSimple /> Upload file
        </SmallButton>
      )}

      {hasValue && (
        <div className={'flex flex-col items-center gap-6 text-center'}>
          <div className="relative">
            <div className={cn('transition-all', isLoading && 'opacity-5')}>
              {isFileImage ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                  src={preview}
                  alt="File Preview"
                  className={cn(
                    'h-24 w-24 overflow-hidden rounded-md border border-fog object-contain',
                    '[background-image:linear-gradient(45deg,#eee_25%,transparent_25%),linear-gradient(135deg,#eee_25%,transparent_25%),linear-gradient(45deg,transparent_75%,#eee_75%),linear-gradient(135deg,transparent_75%,#eee_75%)]',
                    '[background-position:0_0,10px_0,10px_-10px,0px_10px] [background-size:20px_20px]'
                  )}
                  onClick={triggerFileInput}
                />
              ) : (
                <File fontSize={48} />
              )}
            </div>
            {
              <SmallSpinner
                className={cn(
                  'pointer-events-none absolute inset-0 transition-all',
                  isLoading ? 'opacity-100' : 'opacity-0'
                )}
              />
            }
          </div>

          <p className="bits-text-caption break-all">
            {fileName} {!!fileSize && `(${fileSize.toFixed(2)} MB)`}
          </p>

          <div className="flex gap-2">
            <SmallButton variant="outline" onClick={triggerFileInput}>
              <ArrowsClockwise /> Change
            </SmallButton>
            <SmallButton variant="destructive-outline" onClick={handleRemove}>
              <TrashSimple /> Remove
            </SmallButton>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-2">
        <p className="bits-text-caption text-pretty text-center text-smoke">
          {description}
        </p>
        {fileError ? (
          <p className="bits-text-caption text-pretty text-center text-grapefruit">
            {fileError}
          </p>
        ) : null}
      </div>
    </div>
  );
};
