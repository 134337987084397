import * as React from 'react';
import { WarningCircle } from '@phosphor-icons/react';

import { cn } from './utils/cn';

const Callout = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'bits-text-button-2 flex items-center gap-2.5 rounded-md bg-khaki-light p-3',
      '[&_a]:underline',
      className
    )}
    {...props}
  >
    <WarningCircle className="h-6 w-6 shrink-0" />
    <div>{children}</div>
  </div>
));
Callout.displayName = 'Callout';

export { Callout };
