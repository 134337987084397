import {
  createContext,
  ElementType,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import { stringToSlug } from '@bits/i18n';
import { FunnelSimple } from '@phosphor-icons/react';

import { BitsButton } from '../bits-button';
import { Input } from '../input';
import { useConfirmModal } from '../modals/confirm-modal';
import { cn } from '../utils/cn';
import { ActiveFilters } from './advanced-filters-active-filters';
import { FilterDropdown } from './advanced-filters-dropdown';

export type AdvancedFilterContext = Pick<
  AdvancedFiltersProps,
  | 'filters'
  | 'activeFilters'
  | 'onFiltersChange'
  | 'savedFilters'
  | 'onSavedFiltersChange'
  | 'onActiveSavedFilterChange'
> & {
  activeSavedFilter?: SavedAdvancedFilter;
  onClickEditSavedFilter?: (id: string) => void;
  removeSavedFilter: (id: string) => void;
  mode: FilterMode;
};

const filterContext = createContext<AdvancedFilterContext>({
  filters: [],
  activeFilters: {},
  onFiltersChange: () => {},
  savedFilters: [],
  activeSavedFilter: undefined,
  onActiveSavedFilterChange: () => {},
  onSavedFiltersChange: () => {},
  removeSavedFilter: () => {},
  mode: 'filter',
});

export const useFilterContext = () => useContext(filterContext);

export type AdvancedFilterObject = Record<
  string,
  (string | undefined | Date)[]
>;

export type SavedAdvancedFilter = {
  id: string;
  label: string;
  filters: AdvancedFilterObject;
};

export type AdvancedFilterGroup = {
  label: string;
  id: string;
  icon: ElementType;
} & (
  | {
      type: 'options';
      options: {
        label: ReactNode;
        value: string;
        count?: number;
      }[];
    }
  | {
      type: 'date-range';
      presets: {
        label: string;
        from?: string | Date;
        to?: string | Date;
        count?: number;
      }[];
    }
);

type FilterMode = 'edit' | 'save' | 'filter';

export interface AdvancedFiltersProps {
  /**
   * List of filters to display
   */
  filters: AdvancedFilterGroup[];
  /**
   * Active filters
   */
  activeFilters: AdvancedFilterObject;
  /**
   * Callback when filters change
   */
  onFiltersChange: (filters: AdvancedFilterObject) => void;
  /**
   * List of saved filters
   * @optional
   */
  savedFilters?: SavedAdvancedFilter[];
  /**
   * Callback when saved filters change
   */
  onSavedFiltersChange?: (filters: SavedAdvancedFilter[]) => void;
  /**
   * The ID of an active saved filter
   */
  activeSavedFilter?: string;
  /**
   * Callback when an active saved filter is changed
   */
  onActiveSavedFilterChange?: (id?: string) => void;
  /**
   * Label for the trigger button
   * @optional
   */
  triggerLabel?: string;
  /**
   * Content to render on the right side of the filter area
   */
  rightSlot?: ReactNode;
}

/**
 * An advanced filter component that allows users to filter data based on multiple criteria.
 *
 * @example
 * ```tsx
 * <AdvancedFilters
 *   onFiltersChange={setActiveFilters}
 *   activeFilters={activeFilters}
 *   filters={[
 *    {
 *      label: 'Market',
 *      id: 'market',
 *      icon: Money,
 *      options: [
 *        { label: 'US', value: 'us' },
 *        { label: 'EU', value: 'eu' },
 *        { label: 'APAC', value: 'apac' },
 *        { label: 'LATAM', value: 'latam' },
 *      ],
 *    },
 *   ]}
 * />
 * ```
 */
export const AdvancedFilters = (props: AdvancedFiltersProps) => {
  const [mode, setMode] = useState<FilterMode>('filter');
  const [filterNameValue, setFilterNameValue] = useState('');

  const isSavingFilter = mode === 'save' || mode === 'edit';

  const activeSavedFilter = props.savedFilters?.find(
    (filter) => filter.id === props.activeSavedFilter
  );

  const activeFilterArray = [
    ...Object.entries(props.activeFilters).map(([key, values]) => ({
      key,
      values,
    })),
  ];

  /**
   * Filter the filter options based on the active saved filter
   * This ensures you can only narrow an already filtered view based on the
   * options that were included in the saved filter (Linear.app does the same thing)
   */
  const filteredFilterOptions = props.filters.reduce((acc, filter) => {
    if (
      activeSavedFilter &&
      activeSavedFilter.filters[filter.id] &&
      !isSavingFilter
    ) {
      if ('options' in filter) {
        acc.push({
          ...filter,
          options: filter.options.filter(
            (o) => activeSavedFilter.filters[filter.id]?.includes(o.value)
          ),
        });
      }
    } else {
      acc.push(filter);
    }
    return acc;
  }, [] as AdvancedFilterGroup[]);

  const savedFilterInputRef = useRef<HTMLInputElement>(null);
  const handleSaveFilter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!filterNameValue) return;

    const now = new Date();

    const filterId =
      mode === 'edit' && activeSavedFilter
        ? activeSavedFilter.id
        : `${now.getTime()}-${stringToSlug(filterNameValue)}`;

    const newFilter = {
      id: filterId,
      label: filterNameValue,
      filters: props.activeFilters,
    };

    const newSavedFilters = props.savedFilters ? [...props.savedFilters] : [];

    const index = newSavedFilters.findIndex((f) => f.id === filterId);
    if (index !== -1) {
      newSavedFilters[index] = newFilter;
    } else {
      newSavedFilters.push(newFilter);
    }

    props.onFiltersChange({});
    props.onSavedFiltersChange?.(newSavedFilters);
    props.onActiveSavedFilterChange?.(filterId);
    setMode('filter');
  };

  const handleClickEditSavedFilter = () => {
    props.onFiltersChange(activeSavedFilter?.filters || {});
    setFilterNameValue(activeSavedFilter?.label || '');
    setMode('edit');
  };

  const handleCancelSaveFilter = () => {
    if (activeSavedFilter) {
      props.onFiltersChange({});
    }
    setMode('filter');
  };

  const handleClickSaveFilter = () => {
    props.onFiltersChange({
      ...activeSavedFilter?.filters,
      ...props.activeFilters,
    });
    setFilterNameValue('');
    setMode('save');
  };

  const { modal: confirmDeleteModal, trigger: confirmDelete } = useConfirmModal(
    {
      title: 'Remove saved filter',
      description: 'Are you sure you want to remove this saved filter?',
      yes: 'Yes, remove',
      no: 'Cancel',
    }
  );

  const removeSavedFilter = (id: string) => {
    confirmDelete(async () => {
      props.onSavedFiltersChange?.(
        props.savedFilters?.filter((f) => f.id !== id) || []
      );
      props.onActiveSavedFilterChange?.(undefined);
    });
  };

  const contextValue = {
    ...props,
    onClickEditSavedFilter: handleClickEditSavedFilter,
    activeSavedFilter,
    filters: filteredFilterOptions,
    removeSavedFilter,
    mode,
  };

  return (
    <filterContext.Provider value={contextValue}>
      {confirmDeleteModal}
      <div
        className={cn(
          'flex flex-col gap-2',
          isSavingFilter && 'rounded-xl bg-mist p-4'
        )}
      >
        {isSavingFilter && (
          <div>
            <form
              className="flex items-center gap-2"
              onSubmit={handleSaveFilter}
            >
              <Input
                autoFocus
                ref={savedFilterInputRef}
                placeholder="Save filter as..."
                className="bg-white"
                value={filterNameValue}
                onChange={(event) => setFilterNameValue(event.target.value)}
                onKeyDown={(event) => {
                  if (['Escape'].includes(event.key)) {
                    handleCancelSaveFilter();
                  }
                }}
              />
              <BitsButton
                variant="secondary"
                shape="pill"
                size="small"
                onClick={handleCancelSaveFilter}
              >
                Cancel
              </BitsButton>
              <BitsButton
                variant="secondary"
                shape="pill"
                size="small"
                type="submit"
              >
                Save
              </BitsButton>
            </form>
          </div>
        )}
        <div className="flex items-start justify-between gap-2">
          <section aria-label="Filters" className="flex flex-wrap gap-2">
            <FilterDropdown {...contextValue}>
              <button
                className={cn(
                  'bits-text-button-2 flex min-h-[30px] cursor-pointer items-center gap-2 rounded-[4px] border border-fog bg-white px-2.5 outline-none transition-colors',
                  'focus-within:outline-2 focus-within:outline-ink/25 hover:bg-ink/5'
                )}
              >
                <FunnelSimple className="size-5" />{' '}
                {props.triggerLabel || 'Filter'}
              </button>
            </FilterDropdown>

            <div className="hidden flex-wrap gap-2 lg:flex">
              <ActiveFilters
                activeFilterArray={
                  activeFilterArray as { key: string; values: string[] }[]
                }
                filters={filteredFilterOptions}
              />
            </div>
          </section>

          {mode === 'filter' && (
            <div className="flex items-center gap-2">
              <BitsButton
                variant="secondary"
                shape="pill"
                size="small"
                onClick={handleClickSaveFilter}
                className="shrink-0"
              >
                Save filter
              </BitsButton>
              {props.rightSlot}
            </div>
          )}
        </div>
        <div className="flex flex-wrap gap-2 lg:hidden">
          <ActiveFilters
            activeFilterArray={
              activeFilterArray as { key: string; values: string[] }[]
            }
            filters={filteredFilterOptions}
          />
        </div>
      </div>
    </filterContext.Provider>
  );
};
