import { z } from 'zod';

export const whoamiSchema = z.object({
  id: z.string(),
  customerId: z.string().optional().nullable(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  roles: z.array(z.string()),
});
