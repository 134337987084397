import React, { ButtonHTMLAttributes } from 'react';
import { IconProps } from '@phosphor-icons/react';
import { Slot } from '@radix-ui/react-slot';

import { type Icon } from './icon';
import { cn } from './utils/cn';

export interface RoundButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: Icon;
  iconProps?: IconProps;
  size?: 'sm' | 'md';
  asChild?: boolean;
}

export const RoundButton = React.forwardRef<
  HTMLButtonElement,
  RoundButtonProps
>(
  (
    {
      asChild,
      icon: Icon,
      iconProps: { className: iconClassName, ...iconProps } = {},
      size = 'md',
      className,
      ...props
    },
    ref
  ) => {
    const Component = asChild ? Slot : 'button';
    return (
      <Component
        className={cn(
          'bits-text-button-1 inline-flex items-center justify-center rounded-full bg-fog text-primary-foreground ring-offset-background transition-colors',
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          'hover:bg-smoke disabled:pointer-events-none disabled:opacity-50',
          size == 'md' && 'h-9 w-9',
          size == 'sm' && 'h-6 w-6',
          className
        )}
        {...props}
        ref={ref}
      >
        {Icon ? (
          <Icon
            className={cn(
              'fill-ink',
              size == 'md' && 'h-[20px] w-[20px]',
              size == 'sm' && 'h-[16px] w-[16px]',
              iconClassName
            )}
            {...iconProps}
          />
        ) : null}
      </Component>
    );
  }
);

RoundButton.displayName = 'RoundButton';
