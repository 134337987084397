import React, { useState } from 'react';
import { CaretUp } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuItem } from './dropdown-menu';
import { SmallSpinner } from './small-spinner';
import { cn } from './utils/cn';

/**
 * A styled button used for a specific dropdown usecase.
 * If no items are provided, it will render a div, with no dropdown functionality.
 */
export const DropdownButton = ({
  items,
  icon,
  children,
  loading,
}: {
  items: DropdownMenuItem[];
  icon?: React.ReactElement;
  children: React.ReactNode;
  loading?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const Element = items.length > 0 ? 'button' : 'div';

  const content = (
    <Element
      className={cn(
        'bits-text-subtitle-1 flex flex-col gap-2 rounded-md',
        items.length > 0 && !loading && 'hover:bg-sand-light'
      )}
      type={items.length > 0 ? 'button' : undefined}
    >
      <div className="flex items-center justify-center gap-2 rounded-md border border-ink px-3 py-2">
        {loading ? <SmallSpinner /> : icon}
        {children}
        {items.length > 0 && !loading && (
          <div className="flex items-center">
            <CaretUp
              weight="bold"
              className={cn(
                'size-3 transition-all duration-300',
                !open && 'rotate-180'
              )}
            />
          </div>
        )}
      </div>
    </Element>
  );

  return (
    <div className="w-fit">
      {items.length > 0 && !loading ? (
        <DropdownMenu
          items={items}
          align="start"
          sideOffset={4}
          onOpenChange={setOpen}
        >
          {content}
        </DropdownMenu>
      ) : (
        content
      )}
    </div>
  );
};
