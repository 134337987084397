import React from 'react';
import { Check } from '@phosphor-icons/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { cn } from './utils/cn';

export type CheckboxDropdownOption =
  | {
      type?: 'checkbox';
      id: string;
      label: string;
    }
  | {
      type: 'label';
      label: string;
    };

export interface CheckboxDropdownProps {
  children: React.ReactNode;
  title?: string;
  options: CheckboxDropdownOption[];
  values: string[];
  onCheckedItemsChange: (items: string[]) => void;
}

export const CheckboxDropdown = ({
  children,
  options,
  values,
  onCheckedItemsChange,
}: CheckboxDropdownProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          sideOffset={4}
          alignOffset={-8}
          className={dropdownClasses.content}
        >
          <DropdownMenu.Group>
            {options.map((item, i) =>
              item.type === 'checkbox' ? (
                <DropdownMenu.CheckboxItem
                  autoFocus={i === 0 && true}
                  key={item.id}
                  className={dropdownClasses.item}
                  checked={values.some((value) => value === item.id)}
                  onClick={() => {
                    onCheckedItemsChange(
                      values.some((value) => value === item.id)
                        ? values.filter((value) => value !== item.id)
                        : [...values, item.id]
                    );
                  }}
                >
                  <div className="flex size-5 items-center justify-center rounded-[3px] border border-fog bg-white">
                    <DropdownMenu.ItemIndicator>
                      <Check className="size-4" />
                    </DropdownMenu.ItemIndicator>
                  </div>
                  {item.label}
                </DropdownMenu.CheckboxItem>
              ) : (
                <DropdownMenu.Label
                  className={dropdownClasses.label}
                  key={item.label}
                >
                  {item.label}
                </DropdownMenu.Label>
              )
            )}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const dropdownClasses = {
  content:
    'animate-in fade-in-0 shadow-soft bits-text-body-2 w-[300px] rounded-xl bg-white p-3',
  label: 'bits-text-caption text-shadow not-first:mt-4 px-2',
  item: cn(
    'p-2 flex items-center gap-3 cursor-pointer',
    'focus-within:bg-tangerine-light/20 outline-none rounded-md',
    'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-30'
  ),
};
