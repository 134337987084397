import { cn } from '../utils/cn';

export const ThemedSkeleton = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'animate-pulse rounded-md bg-theme-typography-paragraph-textColor/20 text-theme-typography-paragraph-textColor/60',
        className
      )}
      {...props}
    />
  );
};
