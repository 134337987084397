import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { formatFileSize } from '@bits/i18n';

import { cn } from '../utils/cn';
import { ThemedButton } from './themed-button';

export type ThemedFileInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> & {
  label: string;
  buttonLabel: string;
  error?: string;
  optional?: string;
  disabled?: boolean;
  hint?: string;
  value?: { name: string; size: number } | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (value: FileList | null) => void;
};

export const ThemedFileInput = forwardRef<
  HTMLInputElement,
  ThemedFileInputProps
>(
  (
    {
      label,
      error,
      hint,
      onChange,
      buttonLabel,
      onValueChange,
      optional,
      value,
      ...props
    },
    ref
  ) => {
    const uuid = useMemo(() => Math.random().toString(36).substring(7), []);
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    return (
      <div>
        <label>
          {label && (
            <p
              className={cn(
                'mb-2 text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor',
                error && 'text-theme-inputs-errorColor'
              )}
            >
              {label} {optional && `(${optional})`}
            </p>
          )}
        </label>
        <div
          className={cn(
            'relative flex items-center gap-4',
            error &&
              'border-theme-inputs-errorColor text-theme-inputs-errorColor focus-visible:border-theme-inputs-errorColor focus-visible:!outline-theme-inputs-errorColor'
          )}
        >
          <ThemedButton
            onClick={() => inputRef.current?.click()}
            className="shrink-0"
          >
            {buttonLabel}
          </ThemedButton>
          {value && (
            <p className="flex min-w-0 items-center gap-2">
              <span className="truncate" title={value?.name}>
                {value?.name}
              </span>
              <span className="shrink-0 rounded-full bg-theme-typography-paragraph-textColor/10 p-1 px-2 text-theme-inputs-hintAndErrorFontSize text-theme-typography-paragraph-textColor">
                {formatFileSize(value?.size)}
              </span>
            </p>
          )}
          <input
            {...props}
            required={!optional}
            type="file"
            ref={inputRef}
            onChange={(e) => {
              onChange?.(e);
              onValueChange?.(e.target.files);
            }}
            aria-invalid={!!error}
            className={cn('hidden')}
            aria-describedby={
              hint && !error
                ? uuid + 'hint'
                : error
                  ? uuid + 'error'
                  : undefined
            }
          />
        </div>
        {hint && (
          <p
            id={uuid + 'hint'}
            className="mt-1 text-theme-inputs-hintAndErrorFontSize text-theme-inputs-hintTextColor"
          >
            {hint}
          </p>
        )}
        {error && typeof error === 'string' && (
          <p
            id={uuid + 'error'}
            className="mt-1 text-theme-inputs-hintAndErrorFontSize text-theme-inputs-errorColor"
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

ThemedFileInput.displayName = 'ThemedFileInput';
