import { ReactNode, useRef, useState } from 'react';

import { BitsButton } from '../bits-button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../dialog';

interface ConfirmModalProps {
  title?: string;
  description?: ReactNode;
  yes?: string;
  no?: string;
  variant?: 'default' | 'destructive';
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onYes?: () => Promise<void> | void;
  onNo?: () => Promise<void> | void;
}

export const ConfirmModal = ({
  title = 'Are you sure?',
  description = 'This action cannot be undone.',
  yes = 'Yes',
  no = 'Cancel',
  open,
  onOpenChange,
  onYes,
  onNo,
  variant,
}: ConfirmModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleYes = async () => {
    setIsLoading(true);
    await onYes?.();
    setIsLoading(false);
  };

  const handleNo = async () => {
    await onNo?.();
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onNo?.();
    }
    onOpenChange(open);
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent closeButton={false}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4">
          <div className="flex gap-4">
            <BitsButton
              disabled={isLoading}
              onClick={handleNo}
              variant="outline"
              className="w-full"
            >
              {no}
            </BitsButton>
            <BitsButton
              disabled={isLoading}
              loading={isLoading}
              onClick={handleYes}
              variant={variant === 'destructive' ? 'destructive' : 'primary'}
              className="w-full"
            >
              {yes}
            </BitsButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export type UseConfirmModalOptions = Pick<
  ConfirmModalProps,
  'description' | 'title' | 'no' | 'yes' | 'variant'
>;

/**
 * Creates a confirm modal and a trigger function to open it.
 *
 * @param options - The options for the confirm modal.
 * @returns The confirm modal and the trigger function.
 *
 * Call the trigger function to open the modal, and pass an async callback to it.
 * The modal will automatically go into loading state while the async callback is resolving.
 * The modal will close when the async callback is resolved.
 *
 * @example
 * const { trigger: confirmRemove, modal } = useConfirmModal({
 *  title: 'Remove theme',
 *  description: (
 *    <>
 *      This will remove the theme <strong>{name}</strong>. Any markets using
 *      this theme will revert back to use the default theme. This action
 *      cannot be undone. Are you sure you want to continue?
 *    </>
 *  ),
 *  yes: 'Yes, remove theme',
 *  no: 'Cancel',
 * });
 * ...
 * <button onClick={() => confirmRemove(removeTheme)}>Remove theme</button>
 * {modal}
 */
export const useConfirmModal = (options: UseConfirmModalOptions) => {
  const [open, setOpen] = useState(false);

  const { current: callbackState } = useRef({
    resolve: undefined as ((value: boolean) => void) | undefined,
    callback: undefined as (() => PromiseLike<unknown>) | undefined,
  });

  const trigger = async (callback: () => PromiseLike<unknown>) => {
    setOpen(true);

    callbackState.callback = callback as () => PromiseLike<unknown>;

    await new Promise<boolean>((resolve) => {
      callbackState.resolve = resolve;
    });

    setOpen(false);
  };

  const handleResolve = async (value: boolean) => {
    if (value) {
      await callbackState.callback?.();
    }
    callbackState.resolve?.(value);
    callbackState.resolve = undefined;
  };

  return {
    modal: (
      <ConfirmModal
        {...options}
        open={open}
        onOpenChange={setOpen}
        onYes={() => handleResolve(true)}
        onNo={() => handleResolve(false)}
      />
    ),
    trigger,
  };
};
