import { useMemo } from 'react';

/**
 * Returns a unique ID. If an ID is provided, it will be used.
 * Otherwise, a random ID will be generated.
 * @param id - The ID to use.
 * @returns A unique ID.
 */
export const useUuid = (id?: string | null) => {
  const uuid = useMemo(
    () => id ?? Math.random().toString(36).substring(7),
    [id]
  );

  return uuid;
};
