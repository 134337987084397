import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';

import { NativeScrollArea } from '../../native-scroll-area';
import { cn } from '../../utils/cn';
import {
  LoaderOverlay,
  LoaderOverlayText,
} from '../loader-overlay/loader-overlay';

export type NocodeScreenLayoutProps<AsType extends ElementType> = {
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  loading?: boolean;
  loadingText?: LoaderOverlayText;
  className?: string;
  footerClassName?: string;
  as?: AsType;
  centered?: boolean;
  scrollDownIndicator?: string;
} & ComponentPropsWithoutRef<AsType>;

export const NocodeScreenLayout = <AsType extends ElementType>({
  children,
  header,
  footer,
  loading,
  loadingText,
  className,
  as,
  footerClassName,
  centered,
  scrollDownIndicator,
  ...props
}: NocodeScreenLayoutProps<AsType>) => {
  if (loading) return <LoaderOverlay text={loadingText} />;

  const Comp = as || 'div';

  return (
    <Comp
      className={cn('flex h-full w-full flex-col gap-0', className)}
      {...props}
    >
      {header}
      <NativeScrollArea
        fade
        backgroundColor="var(--canvas-backgroundColor)"
        className={cn(centered ? 'm-auto' : 'mb-auto')}
        moreBelowIndicator={scrollDownIndicator}
      >
        <div className="mx-auto max-w-xl p-6 lg:py-12">{children}</div>
      </NativeScrollArea>

      {footer && (
        <div
          className={cn(
            'w-full',
            'border-t border-theme-general-dividerColor pb-6 pt-6 lg:pb-6',
            'lg:[&_button]:w-auto'
          )}
        >
          <div
            className={cn(
              'mx-auto flex w-full max-w-xl justify-end gap-4 px-6 [&_button]:w-full',
              footerClassName
            )}
          >
            {footer}
          </div>
        </div>
      )}
    </Comp>
  );
};
