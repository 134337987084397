import { fetcher } from '@bits/fetcher';
import { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { apiRoutes } from '../api/routes';
import { meSchema } from '../validations/api/me';

export function useCurrentUser(opts?: SWRConfiguration) {
  const data = useSWRImmutable(
    [apiRoutes.me],
    ([url]) => fetcher(meSchema)(url),
    opts
  );
  const isBitsEmployee = data.data?.email.endsWith('@bits.bi');

  return {
    ...data,
    email: data.data?.email,
    isBitsEmployee,
  };
}

export const useUserPrivileges = () => {
  const { data } = useCurrentUser();

  return {
    isOrgOwner: data?.roles.includes('role:org_owner'),
    isBitsEmployee: data?.email.endsWith('@bits.bi'),
  };
};

// TODO: MVP, should be replaced by backend scopes
type UserCapabilities = 'INVITE_USERS';

export const useUserCapabilities = () => {
  const { isOrgOwner } = useUserPrivileges();

  return {
    userCan: (capability: UserCapabilities) => {
      if (capability === 'INVITE_USERS') {
        return isOrgOwner;
      }

      return false;
    },
  };
};
