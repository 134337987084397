import React, { createRef, forwardRef, useState } from 'react';

import { Input, InputProps } from '../input';
import { Label } from '../label';
import { cn } from '../utils/cn';
import { useForwardedRef } from '../utils/use-forwarded-ref';
import { InputErrorAndHint } from './input-error-and-hint';

export type FormColorInputProps = Omit<InputProps, 'onChange'> & {
  label: string;
  hint?: string;
  error?: string;
  onChange?: (e: {
    target: {
      name?: string;
      value: string;
    };
  }) => void;
};

export const FormColorInput = forwardRef<HTMLInputElement, FormColorInputProps>(
  ({ label, hint, error, value, ...props }, ref) => {
    const colorInput = createRef<HTMLInputElement>();
    const textInput = useForwardedRef(ref);
    const preview = createRef<HTMLSpanElement>();
    const [formatError, setFormatError] = useState<string>('');

    const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        props.onChange?.({
          target: {
            name: props.name as string,
            value: e.target.value,
          },
        });

        if (colorInput.current) colorInput.current.value = e.target.value;
        if (textInput.current) textInput.current.value = e.target.value;
        if (preview.current)
          preview.current.style.backgroundColor = e.target.value;

        if (
          textInput.current &&
          !/^#?[0-9A-F]{6}$/i.test(textInput.current.value)
        ) {
          setFormatError('Please enter a valid hex color');
          return;
        }

        setFormatError('');
      }
    };

    return (
      <div>
        <Label>
          <p className="bits-text-body-2 mb-1">{label}</p>
          <div className="relative">
            <Input
              aria-hidden
              ref={textInput}
              {...props}
              value={value}
              onChange={handleChangeColor}
              className={cn(
                error || formatError ? 'border-grapefruit' : 'border-fog'
              )}
              onBlur={(e) => {
                if (!e.target.value.includes('#')) {
                  e.target.value = `#${e.target.value}`;
                  handleChangeColor(e as any);
                }
              }}
            />
            <span
              ref={preview}
              aria-hidden
              className="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded-sm border border-fog"
              style={{ backgroundColor: value as string }}
            >
              <input
                className="absolute h-full w-full cursor-pointer opacity-0"
                ref={colorInput}
                type="color"
                value={value}
                onChange={handleChangeColor}
              />
            </span>
          </div>
        </Label>
        <InputErrorAndHint error={error || formatError} hint={hint} />
      </div>
    );
  }
);
