import { cn } from './utils/cn';

export const TreeBranchLevelIndicator = ({ level }: { level: number }) => {
  if (level < 1) {
    return null;
  }
  return (
    <span
      className={cn(
        'bits-text-caption items-center rounded-full border border-ink px-2 py-0.5 text-center'
      )}
    >
      {'>'.repeat(level)}
    </span>
  );
};
