import { ReactNode } from 'react';
import { Check } from '@phosphor-icons/react';
import { Checkbox, CheckboxIndicator } from '@radix-ui/react-checkbox';

import { cn } from '../utils/cn';

export const ThemedCardCheckbox = ({
  checked,
  onCheckedChange,
  children,
  checkboxAlign = 'middle',
}: {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  children: ReactNode;
  checkboxAlign?: 'middle' | 'top';
}) => {
  return (
    <Checkbox
      checked={checked}
      onCheckedChange={onCheckedChange}
      className={cn(
        'group/checkbox relative block w-full rounded-lg border-2 p-5 pr-12 text-left',
        'transition-all duration-75',
        'hover:bg-theme-typography-paragraph-textColor/5',
        'border-theme-typography-paragraph-textColor/10',
        'data-[state=checked]:border-theme-typography-paragraph-textColor'
      )}
    >
      <div>{children}</div>

      <div
        className={cn(
          'absolute right-5 flex size-6 -translate-y-1/2 items-center justify-center rounded-md border-2',
          'border-theme-typography-paragraph-textColor/20',
          'group-data-[state=checked]/checkbox:border-theme-typography-paragraph-textColor/50',
          checkboxAlign === 'top' && 'top-8',
          checkboxAlign === 'middle' && 'top-1/2'
        )}
      >
        <CheckboxIndicator>
          <Check
            weight="bold"
            className="text-theme-typography-paragraph-textColor duration-75 animate-in fade-in-50 zoom-in-50"
          />
        </CheckboxIndicator>
      </div>
    </Checkbox>
  );
};
