import { useEffect, useRef, useState } from 'react';
import { MagnifyingGlass } from '@phosphor-icons/react';

import { BitsButton } from '../bits-button';
import { cn } from '../utils/cn';
import { SearchInput, SearchInputProps } from './search-input';

/**
 * A responsive search input component that shows a button when the screen is smol
 * and a search input when the screen is large.
 */
export const ResponsiveSearchInput = (props: SearchInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      searchRef.current?.querySelector('input')?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    // Close the search input when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative">
      <BitsButton
        variant="secondary"
        shape="icon"
        size="small"
        icon={MagnifyingGlass}
        onClick={() => setIsOpen(!isOpen)}
        className="md:hidden"
        aria-label="Open search"
      />
      <div
        className={cn(
          'absolute right-0 top-0 hidden md:static md:block',
          isOpen && 'block rounded-lg bg-white'
        )}
        ref={searchRef}
      >
        <SearchInput {...props} />
      </div>
    </div>
  );
};
