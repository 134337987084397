import { useCallback, useMemo, useState } from 'react';

/**
 * Returns a triggerable function that can be used to perform an async action.
 * It will set the loading state and catch any errors that occur during the action.
 */
export const useAsyncAction = <TCallback extends () => PromiseLike<unknown>>(
  cb: TCallback
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const trigger = useCallback(
    async (...args: Parameters<TCallback>) => {
      setIsLoading(true);
      setIsSuccess(false);
      try {
        const response = await cb(...(args as []));
        setIsSuccess(true);
        return response;
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [cb]
  );

  return useMemo(
    () => ({ isLoading, trigger, error, isSuccess }),
    [isLoading, trigger, error, isSuccess]
  );
};
