import { ComponentProps, FormEvent, useEffect, useState } from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

import { Input } from '../input';
import { PopoverPrimitive } from '../popover';

export interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({
  page,
  totalPages = 1,
  onPageChange,
}: PaginationProps) => {
  const actualTotalPages = Math.max(1, totalPages);

  const isFirstPage = page === 1;
  const isLastPage = page === actualTotalPages;

  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(page.toString());

  useEffect(() => {
    setInputValue(page.toString());
  }, [page]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const pageNumber = parseInt(inputValue, 10);
    onPageChange(Math.min(Math.max(1, pageNumber), actualTotalPages));
    setShowInput(false);
  };

  return (
    <div
      className="flex justify-center space-x-2 whitespace-nowrap"
      aria-label="pagination"
    >
      <PaginationButton
        disabled={isFirstPage}
        onClick={() => onPageChange(page - 1)}
      >
        <CaretLeft /> Previous
      </PaginationButton>

      <PopoverPrimitive.Root open={showInput} onOpenChange={setShowInput}>
        <PopoverPrimitive.Trigger asChild>
          <button className="rounded-md p-2 leading-5 ring-fog transition-all hover:ring-2">
            {page} of {actualTotalPages}
          </button>
        </PopoverPrimitive.Trigger>

        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            className="relative w-[200px] rounded-lg bg-white shadow-lg"
            sideOffset={10}
          >
            <form onSubmit={handleSubmit} className="flex items-center gap-2">
              <Input
                placeholder="Go to page"
                value={inputValue}
                type="number"
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
              />
              <button className="shink-0 bits-text-subtitle-2 absolute right-2 flex items-center justify-center rounded-xl bg-fog p-1 px-3">
                Go
              </button>
            </form>
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>

      <PaginationButton
        disabled={isLastPage}
        onClick={() => onPageChange(page + 1)}
      >
        Next <CaretRight />
      </PaginationButton>
    </div>
  );
};

const PaginationButton = (props: ComponentProps<'button'>) => {
  return (
    <button
      className="bits-text-subtitle-1 flex items-center gap-1 rounded-md p-2 px-3 leading-5 hover:enabled:bg-fog active:enabled:bg-fog/60 disabled:opacity-50"
      {...props}
    />
  );
};
