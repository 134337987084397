/**
 * Get the language from the language code
 * @param lang - The language code
 * @param code - The code to get the language from
 * @returns The language
 */
export const getLanguageFromCode = (lang: string, code: string) => {
  const translator = new Intl.DisplayNames([lang], { type: 'language' });
  return translator.of(code);
};
