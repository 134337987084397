import { ReactNode, useEffect, useRef, useState } from 'react';
import { DialogTitle } from '@radix-ui/react-dialog';

import { ThemedButton } from './themed-button';
import { ThemedDialog, ThemedDialogContent } from './themed-dialog';
import { ThemedHeading } from './themed-heading';

type ThemedConfirmModalOption = {
  label: string;
  onClick?: () => void;
  variant?: 'primary' | 'outline';
};

interface ThemedConfirmModalProps {
  title?: string;
  description?: ReactNode;
  variant?: 'default' | 'destructive';
  open: boolean;
  onOpenChange: (open: boolean) => void;
  buttons: ThemedConfirmModalOption[];
}

export const ThemedConfirmDialog = ({
  title = 'Are you sure?',
  description = 'This action cannot be undone.',
  open,
  onOpenChange,
  buttons,
}: ThemedConfirmModalProps) => {
  const [currentButtonLoading, setCurrentButtonLoading] = useState<
    number | null
  >(null);

  const handleClickOption = async (
    clickable: { onClick?: () => void },
    index: number
  ) => {
    setCurrentButtonLoading(index);
    await clickable.onClick?.();
    setCurrentButtonLoading(null);
    onOpenChange(false);
  };

  useEffect(() => {
    if (!open) {
      setCurrentButtonLoading(null);
    }
  }, [open]);

  return (
    <ThemedDialog open={open} onOpenChange={onOpenChange}>
      <ThemedDialogContent closeButton={false} variant="sm">
        <DialogTitle asChild>
          <ThemedHeading>{title}</ThemedHeading>
        </DialogTitle>
        <div className="grid gap-4">
          <p>{description}</p>
          <div className="flex gap-4">
            {buttons.map(({ label, ...buttonProps }, index) => (
              <ThemedButton
                key={label}
                disabled={currentButtonLoading !== null}
                loading={currentButtonLoading === index}
                variant={buttonProps.variant}
                onClick={() => handleClickOption(buttonProps, index)}
                className="w-full"
              >
                {label}
              </ThemedButton>
            ))}
          </div>
        </div>
      </ThemedDialogContent>
    </ThemedDialog>
  );
};

type UseConfirmModalTriggerOptions = Pick<
  ThemedConfirmModalProps,
  'title' | 'description' | 'buttons'
>;

/**
 * Creates a confirm modal and a trigger function to open it.
 *
 * @param options - The options for the confirm modal.
 * @returns The confirm modal and the trigger function.
 *
 * Call the trigger function to open the modal, and pass an async callback to it.
 * The modal will automatically go into loading state while the async callback is resolving.
 * The modal will close when the async callback is resolved.
 */
export const useThemedConfirmModal = () => {
  const awaitedCallback = useRef<() => Promise<unknown>>();
  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState<UseConfirmModalTriggerOptions>({
    title: 'Are you sure?',
    description: 'This action cannot be undone.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {},
      },
    ],
  });

  const trigger = async (options: UseConfirmModalTriggerOptions) => {
    setOptions(options);
    setOpen(true);

    return new Promise<boolean>((resolve) => {
      awaitedCallback.current = () => {
        resolve(true);
        return Promise.resolve();
      };
    });
  };

  useEffect(() => {
    if (!open) {
      awaitedCallback.current?.();
    }
  }, [open]);

  return {
    modal: (
      <ThemedConfirmDialog {...options} open={open} onOpenChange={setOpen} />
    ),
    trigger,
  };
};
