/**
 * A subset of ISO 4217 "widely used" currencies
 * From https://en.wikipedia.org/wiki/ISO_4217
 */
export const iso4217BankingCurrencies = [
  'USD',
  'EUR',
  'JPY',
  'GBP',
  'CHF',
  'CAD',
  'AUD',
  'NZD',
  'CNY',
  'HKD',
  'SGD',
  'NOK',
  'SEK',
  'DKK',
  'TRY',
  'AED',
  'SAR',
  'INR',
  'IDR',
  'MYR',
  'THB',
  'KRW',
  'ZAR',
  'MXN',
  'BRL',
  'ARS',
] as const;

export type ISO4217BankingCurrency = (typeof iso4217BankingCurrencies)[number];

export const currencyName = (
  currency: ISO4217BankingCurrency,
  language: string
) => {
  const translator = new Intl.DisplayNames([language], { type: 'currency' });
  return translator.of(currency);
};

export const getCurrenciesAsOptions = (language: string) => {
  return iso4217BankingCurrencies.map((currency) => ({
    label: currencyName(currency, language) as string,
    value: currency,
  }));
};
