import { Check } from '@phosphor-icons/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { dropdownClasses } from '../checkbox-dropdown';
import { AdvancedFilterGroup, AdvancedFilterObject } from './advanced-filters';

export const OptionsFilter = ({
  id,
  filter,
  activeFilters,
  onFiltersChange,
  setSection,
}: {
  id: string;
  filter: AdvancedFilterGroup;
  activeFilters: AdvancedFilterObject;
  onFiltersChange: (filters: AdvancedFilterObject) => void;
  setSection: (section: string) => void;
}) => {
  return (
    <>
      {'options' in filter &&
        filter.options.map(({ label, value, count }, i) => (
          <DropdownMenu.CheckboxItem
            autoFocus={i === 0 && true}
            key={value}
            onClick={(event) => {
              // If alt is pressed, only apply this filter
              if (event.altKey) {
                onFiltersChange({
                  ...activeFilters,
                  [id]: [value],
                });
                return;
              }

              // Otherwise, toggle the filter
              const currentFilters = activeFilters[id] || [];
              onFiltersChange({
                ...activeFilters,
                [id]: currentFilters.includes(value)
                  ? currentFilters.filter((v) => v !== value)
                  : [...currentFilters, value],
              });
            }}
            checked={activeFilters[id]?.includes(value)}
            onKeyDown={(event) => {
              if (['ArrowLeft'].includes(event.key)) {
                setSection('');
              }
            }}
            className={dropdownClasses.item}
          >
            <div className="flex size-5 items-center justify-center rounded-[3px] border border-fog bg-white">
              {activeFilters[id]?.includes(value) && (
                <DropdownMenu.ItemIndicator>
                  <Check aria-hidden className="size-4" />
                </DropdownMenu.ItemIndicator>
              )}
            </div>

            {label}

            {count !== undefined && (
              <div className="bits-text-button-2 ml-auto">{count}</div>
            )}
          </DropdownMenu.CheckboxItem>
        ))}
    </>
  );
};
