import React, { HTMLProps } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../utils/cn';

export interface ThemedHeadingProps
  extends Omit<HTMLProps<HTMLHeadingElement>, 'ref'> {
  asChild?: boolean;
}

export const ThemedHeading = ({
  asChild = false,
  className,
  ...props
}: ThemedHeadingProps) => {
  const Comp = asChild ? Slot : 'h1';
  return (
    <Comp
      className={cn(
        'text-theme-typography-title-textColor [font-weight:var(--typography-title-fontWeight)]',
        'text-theme-typography-title-fontSize [letter-spacing:var(--typography-title-letterSpacing)]',
        'text-pretty leading-tight [text-transform:var(--typography-title-uppercase)]',
        className
      )}
      {...props}
    />
  );
};
