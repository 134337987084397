import { cn } from './utils/cn';

function Skeleton({
  className,
  pulse = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  pulse?: boolean;
}) {
  return (
    <div
      className={cn(
        'rounded-md bg-muted dark:bg-muted-foreground',
        pulse && 'animate-pulse',
        className
      )}
      {...props}
    />
  );
}

export { Skeleton };
