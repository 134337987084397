import type { NextApiHandler, NextApiRequest, NextApiResponse } from 'next';
import { ZodError } from 'zod';

import { HttpError } from './http-error';

export const withErrors = (next: NextApiHandler) => {
  return async (req: NextApiRequest, res: NextApiResponse) => {
    try {
      await next(req, res);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      if (error instanceof HttpError) {
        return res
          .status(error.statusCode)
          .json({ message: error.message, ...error.json });
      }

      if (error instanceof ZodError) {
        return res.status(422).json({ message: parseZodError(error) });
      }

      return res.status(500).json({ message: 'Internal Server Error' });
    }
  };
};

/**
 * Parse Zod error message to JSON, if possible
 */
const parseZodError = (error: ZodError) => {
  try {
    return JSON.parse(error.message);
  } catch (e) {
    return error.message;
  }
};
