import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';

import { SmallSpinner } from '../small-spinner';
import { cn } from '../utils/cn';

export interface ThemedButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  children: ReactNode;
  loading?: boolean;
  variant?: 'primary' | 'outline' | 'link';
}

export const ThemedButton = React.forwardRef<
  HTMLButtonElement,
  ThemedButtonProps
>(
  (
    {
      asChild = false,
      className,
      icon,
      iconPosition = 'left',
      children,
      loading,
      variant = 'primary',
      type = 'button',
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          'relative inline-flex items-center justify-center gap-2 overflow-hidden transition-all duration-75',
          (variant === 'primary' || variant === 'outline') && [
            'min-w-[120px] p-6 py-3',
            'text-theme-buttons-primary-fontSize [letter-spacing:var(--buttons-primary-letterSpacing)] [text-transform:var(--buttons-primary-uppercase)]',
            '[box-shadow:var(--buttons-primary-shadow)] [font-weight:var(--buttons-primary-fontWeight)]',
            'rounded-[var(--buttons-primary-cornerRadius)]',
          ],
          variant === 'primary' && [
            'border-theme-buttons-primary-normal-borderColor bg-theme-buttons-primary-normal-backgroundColor text-theme-buttons-primary-normal-textColor',
            'hover:border-theme-buttons-primary-hover-borderColor hover:bg-theme-buttons-primary-hover-backgroundColor hover:text-theme-buttons-primary-hover-textColor',
            '[border-width:var(--buttons-primary-borderWidth)] active:bg-theme-buttons-primary-hover-backgroundColor/50 active:transition-none',
          ],
          variant === 'outline' && [
            'bg-transparent',
            'border-2 border-theme-general-dividerColor',
            'text-theme-typography-paragraph-textColor',
            'hover:bg-theme-typography-paragraph-textColor/5',
          ],
          variant === 'link' && [
            'text-theme-typography-paragraph-fontSize underline hover:opacity-70',
          ],
          'text-pretty text-center aria-disabled:pointer-events-none aria-disabled:opacity-50',
          'outline-none focus-visible:outline-2 focus-visible:outline-theme-general-focusColor',
          '[&_svg]:size-4',
          className
        )}
        type={type}
        {...props}
        disabled={props.disabled || loading}
        aria-disabled={props.disabled || loading}
        ref={ref}
      >
        {iconPosition === 'left' && icon}
        <Slottable>{children}</Slottable>
        {iconPosition === 'right' && icon}
        {loading && (
          <div className="absolute inset-0 flex bg-theme-buttons-primary-normal-backgroundColor">
            <SmallSpinner className="m-auto" />
          </div>
        )}
      </Comp>
    );
  }
);
ThemedButton.displayName = 'ThemedButton';
