import React, { isValidElement, ReactElement, useState } from 'react';
import { Icon } from '@phosphor-icons/react';
import * as Dropdown from '@radix-ui/react-dropdown-menu';

export type DropdownMenuItem = {
  label: string;
  icon?: Icon | ReactElement;
  onClick: () => void;
};

/**
 * A dropdown menu component.
 * It is used to render a dropdown menu with a list of items.
 *
 * The children should be a Button element.
 */
export const DropdownMenu = ({
  children,
  items,
  onOpenChange,
  ...props
}: {
  children: React.ReactNode;
  items: DropdownMenuItem[];
  onOpenChange?: (open: boolean) => void;
} & Dropdown.DropdownMenuContentProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown.Root
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        onOpenChange?.(open);
      }}
    >
      <Dropdown.Trigger
        className="focus:outline-offset-4 focus:outline-ink"
        asChild
      >
        {children}
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className="bits-text-body-2 mt-1 flex w-[280px] flex-col rounded-xl bg-white p-3 shadow-soft animate-in fade-in-0"
          {...props}
        >
          {items.map(({ icon, label, onClick }) => (
            <Dropdown.Item
              key={label}
              onClick={onClick}
              className="bits-text-body-1 flex cursor-pointer items-center gap-2 rounded-md p-1 focus:outline-none data-[highlighted]:bg-black/5"
            >
              {icon && iconOrElement(icon)}
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const iconOrElement = (icon: Icon | ReactElement) => {
  if (isValidElement(icon)) {
    return icon;
  }

  const IconComponent = icon as Icon;

  return <IconComponent className="size-5" />;
};
