import { HTMLProps, ReactNode } from 'react';
import { Circle } from '@phosphor-icons/react';

import { cn } from './utils/cn';

export const CardRadioGroup = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      role="radiogroup"
      {...props}
      className={cn('grid gap-3', props.className)}
    />
  );
};

export const CardRadioItem = ({
  label,
  description,
  checked,
  onCheckedChange,
  value,
  disabled,
}: {
  label?: ReactNode;
  description?: ReactNode;
  onCheckedChange: (value: string) => void;
  checked: boolean;
  value: string;
  disabled?: boolean;
}) => {
  return (
    <button
      role="radio"
      aria-checked={checked}
      onClick={() => {
        onCheckedChange?.(value);
      }}
      className={cn(
        'flex cursor-pointer justify-between gap-2 rounded-lg border-2 border-fog p-4 text-left',
        'transition-all hover:bg-tangerine-light/20',
        disabled && 'cursor-not-allowed opacity-50',
        checked && 'border-ink'
      )}
    >
      <div className={cn('bits-text-body-1 text-ink', 'grid gap-1')}>
        <p className="bits-text-subtitle-1">{label}</p>
        {description && (
          <p className="bits-text-body-2 text-ink/60">{description}</p>
        )}
      </div>
      <div
        aria-hidden
        className={cn('flex h-5 w-5 shrink-0 rounded-full border')}
      >
        {checked && (
          <span className="relative m-auto flex items-center justify-center animate-in zoom-in-50">
            <Circle weight="fill" className="size-[11px] text-ink" />
          </span>
        )}
      </div>
    </button>
  );
};
