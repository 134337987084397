import { ComponentProps, ReactNode, useEffect, useMemo, useState } from 'react';
import { CaretLeft, CaretRight, MagnifyingGlass } from '@phosphor-icons/react';

import { cn } from '../utils/cn';
import { ThemedInput } from './themed-input';

type ThemedSearchableCardListProps = {
  searchValue: string;
  onSearchChange: (value: string) => void;
  options: {
    label: ReactNode;
    searchValue: string;
    value: string;
  }[];
  onOptionClick: (value: string) => void;
  noResultsSlot: ReactNode;
  nextPageText: string;
  previousPageText: string;
  searchPlaceholder: string;
  renderPageOfTotalPages?: (page: number, totalPages: number) => ReactNode;
};

export const fuzzyishStringMatch = (search: string, target: string) =>
  search
    .split(' ')
    .every((word) => target.toLowerCase().includes(word.toLowerCase()));

const PAGE_SIZE = 12;

export const ThemedSearchableCardList = ({
  searchValue,
  onSearchChange,
  options,
  onOptionClick,
  noResultsSlot,
  renderPageOfTotalPages,
  searchPlaceholder = 'Search',
  nextPageText = 'Next page',
  previousPageText = 'Previous page',
}: ThemedSearchableCardListProps) => {
  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      fuzzyishStringMatch(searchValue, option.searchValue)
    );
  }, [options, searchValue]);

  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(filteredOptions.length / PAGE_SIZE);

  const handlePageChange = (page: number) => {
    setPage(Math.min(Math.max(1, page), totalPages));
  };

  useEffect(() => {
    setPage(1);
  }, [searchValue, options]);

  return (
    <div className="flex flex-col gap-4 @container/searchable-card-list">
      <div>
        <ThemedInput
          value={searchValue}
          onValueChange={onSearchChange}
          placeholder={searchPlaceholder}
          type="text"
          icon={
            <MagnifyingGlass
              className="size-5 text-theme-inputs-textColor"
              aria-hidden
            />
          }
          iconPosition="left"
          optional
        />
      </div>
      {filteredOptions.length ? (
        <ul className="grid grid-cols-2 gap-2.5 @w-lg/searchable-card-list:grid-cols-3">
          {filteredOptions
            .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
            .map((option) => (
              <li key={option.value} className="">
                <button
                  className={cn(
                    'block h-[116px] appearance-none text-left transition duration-75',
                    'w-full overflow-hidden rounded-md border p-5',
                    'border-theme-typography-paragraph-textColor/20',
                    'hover:bg-theme-typography-paragraph-textColor/5',
                    'active:bg-theme-typography-paragraph-textColor/10'
                  )}
                  onClick={() => onOptionClick(option.value)}
                >
                  <div className="size-full text-sm">{option.label}</div>
                </button>
              </li>
            ))}
        </ul>
      ) : (
        <p
          className="text-center text-theme-typography-paragraph-textColor"
          aria-live="assertive"
        >
          {noResultsSlot || 'No results found'}
        </p>
      )}
      {filteredOptions.length > 0 && filteredOptions.length > PAGE_SIZE && (
        <nav
          aria-label="Pagination"
          className="flex items-center gap-2 self-center"
        >
          <PaginationButton
            disabled={page === 1}
            aria-label={previousPageText || 'Previous page'}
            onClick={() => handlePageChange(page - 1)}
          >
            <CaretLeft />
          </PaginationButton>
          <p>
            {renderPageOfTotalPages?.(page, totalPages) ||
              `${page} of ${totalPages || 1}`}
          </p>
          <PaginationButton
            disabled={page === totalPages}
            aria-label={nextPageText || 'Next page'}
            onClick={() => handlePageChange(page + 1)}
          >
            <CaretRight />
          </PaginationButton>
        </nav>
      )}
    </div>
  );
};

const PaginationButton = ({
  className,
  ...props
}: ComponentProps<'button'>) => {
  return (
    <button
      className={cn(
        'rounded-md border-2 p-2',
        'border-theme-typography-paragraph-textColor/25',
        'hover:bg-theme-typography-paragraph-textColor/5',
        'active:bg-theme-typography-paragraph-textColor/10',
        'disabled:opacity-35'
      )}
      {...props}
    />
  );
};
