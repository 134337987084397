import { ReactNode, useState } from 'react';
import {
  ArrowLeft,
  FunnelSimple,
  Stack,
  StackSimple,
  TrashSimple,
} from '@phosphor-icons/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { dropdownClasses } from '../checkbox-dropdown';
import { BitsTooltip, Tooltip } from '../tooltip';
import { cn } from '../utils/cn';
import {
  AdvancedFilterContext,
  AdvancedFilterGroup,
  useFilterContext,
} from './advanced-filters';
import { DateRangeFilter } from './date-range-filter';
import { OptionsFilter } from './options-filter';

interface FilterDropdownProps extends AdvancedFilterContext {
  section?: string;
  children: ReactNode;
  backButton?: boolean;
}

export const FilterDropdown = ({
  filters,
  activeFilters,
  onFiltersChange,
  savedFilters,
  section: sectionOverride,
  children,
  backButton = true,
  onActiveSavedFilterChange,
}: FilterDropdownProps) => {
  const { removeSavedFilter, activeSavedFilter, mode } = useFilterContext();
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState('');

  const close = () => {
    setOpen(false);
    setSection('');
  };

  const shownSection = sectionOverride || section;

  return (
    <DropdownMenu.Root open={open}>
      <DropdownMenu.Trigger
        onClick={() => {
          setOpen(!open);
          setSection('');
        }}
        onKeyDown={(event) => {
          if (['Enter', ' '].includes(event.key)) {
            setOpen(!open);
            setSection('');
          }
        }}
        asChild
      >
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          sideOffset={4}
          alignOffset={-8}
          className={dropdownClasses.content}
          onFocusOutside={close}
          onEscapeKeyDown={close}
          onCloseAutoFocus={close}
          onInteractOutside={close}
        >
          {shownSection === '' && (
            <>
              <DropdownMenu.Group className="grid animate-in fade-in-0">
                {filters.map(({ id, icon: Icon, label, ...filter }) => (
                  <DropdownMenu.Item
                    key={id}
                    onSelect={() => setSection(id)}
                    onKeyDown={(event) => {
                      if (['ArrowRight'].includes(event.key)) {
                        setSection(id);
                      }
                    }}
                    className={dropdownClasses.item}
                    disabled={
                      'options' in filter && filter.options.length === 0
                    }
                  >
                    <Icon className="size-5" /> {label}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Group>

              {/* SAVED FILTERS */}
              {savedFilters && savedFilters.length > 0 && (
                <DropdownMenu.Group>
                  <DropdownMenu.Label
                    className={cn(dropdownClasses.label, 'mt-4')}
                  >
                    Saved filters
                  </DropdownMenu.Label>

                  {savedFilters?.map((savedFilter, i) => (
                    <DropdownMenu.Item
                      key={i}
                      onSelect={() => {
                        onFiltersChange({});
                        onActiveSavedFilterChange?.(savedFilter.id);
                        close();
                      }}
                      className={cn(dropdownClasses.item, 'group')}
                    >
                      <Stack className="aria-hidden size-5 shrink-0" />
                      <span className="truncate pr-4" title={savedFilter.label}>
                        {savedFilter.label}
                      </span>
                      <button
                        aria-hidden
                        title="Remove filter"
                        className="absolute right-3 hidden rounded-md p-2 text-ink hover:bg-ink/5 group-hover:block"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          removeSavedFilter(savedFilter.id);
                        }}
                      >
                        <TrashSimple className="size-4" />
                      </button>
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Group>
              )}
            </>
          )}

          {filters.map(
            ({ label, id, type, ...filter }) =>
              shownSection === id && (
                <DropdownMenu.Group
                  key={id}
                  className="grid animate-in fade-in-0"
                >
                  {backButton && (
                    <DropdownMenu.Item
                      aria-label="Go back"
                      className={dropdownClasses.item}
                      onKeyDown={(event) => {
                        if (['ArrowLeft'].includes(event.key)) {
                          setSection('');
                        }
                      }}
                      onClick={() => setSection('')}
                    >
                      <ArrowLeft className="size-5" aria-hidden />
                      {label}
                    </DropdownMenu.Item>
                  )}

                  {type === 'options' && (
                    <OptionsFilter
                      id={id}
                      filter={filter as AdvancedFilterGroup}
                      activeFilters={activeFilters}
                      onFiltersChange={onFiltersChange}
                      setSection={setSection}
                    />
                  )}

                  {type === 'date-range' && (
                    <DateRangeFilter
                      filter={filter as AdvancedFilterGroup}
                      activeFilters={activeFilters}
                      onFiltersChange={onFiltersChange}
                      id={id}
                    />
                  )}

                  {activeSavedFilter && mode === 'filter' && (
                    <BitsTooltip
                      content="The available filter options are based on the current active filter. To see all options, remove the active filter."
                      delayDuration={0}
                      contentProps={{
                        align: 'start',
                        side: 'bottom',
                      }}
                    >
                      <p className="bits-text-body-2 mt-4 text-ink/40">
                        <Stack
                          className="-mt-0.5 mr-1 inline-block align-middle"
                          weight="bold"
                        />
                        <span className="mr-0.5 font-bold">
                          {activeSavedFilter.label}
                        </span>{' '}
                        is active
                      </p>
                    </BitsTooltip>
                  )}
                </DropdownMenu.Group>
              )
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
