import { CSSProperties } from 'react';

import { cn } from './utils/cn';

export type HRWithLabelProps = { label: string };

export const HRWithLabel = ({ label }: HRWithLabelProps) => {
  return (
    <div
      className={cn(
        'relative overflow-visible border-b border-fog',
        'after:absolute after:-bottom-4 after:content-[var(--after-content)]',
        'after:bits-text-button-2 after:rounded-full after:border-fog after:text-ink',
        'after:right-1/2 after:translate-x-1/2 after:border after:bg-white after:px-4 after:py-2'
      )}
      style={
        {
          '--after-content': `"${label}"`,
        } as CSSProperties
      }
    >
      <hr />
    </div>
  );
};
