import {
  ComponentPropsWithoutRef,
  ComponentType,
  ElementType,
  ReactNode,
} from 'react';
import { X } from '@phosphor-icons/react';

import { cn } from '../utils/cn';
import { useFilterContext } from './advanced-filters';
import { FilterDropdown } from './advanced-filters-dropdown';

interface ActiveFilterButtonProps {
  label?: string;
  ariaLabel?: string;
  values: ReactNode[];
  section?: string;
  onClear: () => void;
  onClick?: () => void;
  icon?: ElementType;
  dropdown?:
    | ComponentType<ComponentPropsWithoutRef<typeof FilterDropdown>>
    | false;
}
export const ActiveFilterButton = ({
  label,
  ariaLabel,
  values,
  onClear,
  section,
  onClick,
  icon: Icon,
  dropdown: Dropdown = FilterDropdown,
}: ActiveFilterButtonProps) => {
  const filterContext = useFilterContext();

  const mainButton = (
    <button
      onClick={onClick}
      className={cn(
        'bits-text-button-2 flex h-full cursor-pointer items-center gap-2 px-2.5 py-1 text-left hover:bg-ink/5',
        'outline-offset-2 transition-colors focus-within:outline-2 focus-within:outline-ink/25'
      )}
    >
      {Icon && <Icon className="size-4" weight="bold" />}
      {label && <span className="text-ash">{label}:</span>}{' '}
      <div className="flex flex-wrap gap-x-2">
        {values.map((p, i) => (
          <span key={i}>{p}</span>
        ))}
      </div>
    </button>
  );

  return (
    <div className="relative flex h-full rounded-[4px] border border-fog bg-white">
      {!Dropdown && mainButton}
      {Dropdown && (
        <Dropdown {...filterContext} section={section} backButton={false}>
          {mainButton}
        </Dropdown>
      )}
      <button
        className="justify-center p-1 outline-offset-2 focus-within:outline-2 focus-within:outline-ink/25 hover:bg-ink/5"
        onClick={onClear}
        aria-label={'Clear filter: ' + (ariaLabel || label)}
      >
        <X className="size-4" aria-hidden />
      </button>
    </div>
  );
};
