import { Pencil, Stack, Trash } from '@phosphor-icons/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { motion } from 'framer-motion';

import { dropdownClasses } from '../checkbox-dropdown';
import { useConfirmModal } from '../modals/confirm-modal';
import { AdvancedFilterGroup, useFilterContext } from './advanced-filters';
import { ActiveFilterButton } from './advanced-filters-active-filter-button';
import { formatActiveFilterLabel } from './advanced-filters-utils';

export const ActiveFilters = ({
  activeFilterArray,
  filters,
}: {
  activeFilterArray: { key: string; values: string[] }[];
  filters: AdvancedFilterGroup[];
}) => {
  const { activeFilters, onFiltersChange, activeSavedFilter, mode } =
    useFilterContext();

  return (
    <>
      {/* Active saved filter button */}
      {activeSavedFilter && mode === 'filter' && (
        <motion.div layout={'position'} key={activeSavedFilter.id}>
          <ActiveSavedFilterButton />
        </motion.div>
      )}

      {/* Active filters */}
      {activeFilterArray.map(({ key, values }) => {
        if (!values.length) return null;
        const filter = filters.find((f) => f.id === key);
        if (!filter || !filter.label) return null;

        const optionValue = formatActiveFilterLabel(filter, values);

        return (
          <motion.div key={key} layout={'position'}>
            <ActiveFilterButton
              section={key}
              label={filter?.label || ''}
              values={optionValue}
              onClear={() => {
                const newActiveFilters = { ...activeFilters };
                delete newActiveFilters[key];
                onFiltersChange(newActiveFilters);
              }}
            />
          </motion.div>
        );
      })}
    </>
  );
};

const ActiveSavedFilterButton = () => {
  const { activeSavedFilter, onActiveSavedFilterChange } = useFilterContext();

  if (!activeSavedFilter) return null;

  return (
    <ActiveFilterButton
      ariaLabel={activeSavedFilter.label}
      values={[activeSavedFilter.label]}
      icon={Stack}
      dropdown={ActiveSavedFilterDropdown}
      onClear={() => {
        onActiveSavedFilterChange?.(undefined);
      }}
    />
  );
};

const ActiveSavedFilterDropdown = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { activeSavedFilter, onClickEditSavedFilter, removeSavedFilter } =
    useFilterContext();

  if (!activeSavedFilter) return null;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content align="start" className={dropdownClasses.content}>
          <DropdownMenu.Item
            className={dropdownClasses.item}
            onClick={() => {
              onClickEditSavedFilter?.(activeSavedFilter.id);
            }}
          >
            <Pencil className="size-5 shrink-0" />
            Edit
          </DropdownMenu.Item>
          <DropdownMenu.Item
            className={dropdownClasses.item}
            onClick={() => {
              removeSavedFilter(activeSavedFilter.id);
            }}
          >
            <Trash className="size-5 shrink-0" />
            Remove
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
