/**
 * Converts a string to a slug
 * @param str - The string to convert to a slug
 * @returns The slugified string
 */
export const stringToSlug = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // Remove special characters except whitespace and hyphens
    .replace(/[\s_]+/g, '-') // Replace whitespace and underscores with single hyphen
    .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
};
