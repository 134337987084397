import {
  createContext,
  CSSProperties,
  RefObject,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ArrowDown } from '@phosphor-icons/react';
import clsx from 'clsx';

import { cn } from './utils/cn';

const observeScrollHeight = (
  target?: HTMLDivElement | null,
  callback?: (scrollHeight: number) => void
) => {
  let value = target?.scrollHeight;
  let raf: number;

  const tick = () => {
    if (!target) return;

    if (target.scrollHeight !== value) {
      value = target.scrollHeight;
      callback?.(value);
    }
    raf = requestAnimationFrame(tick);
  };

  tick();

  return () => {
    cancelAnimationFrame(raf);
  };
};

const nativeScrollAreaContext = createContext<{
  viewportRef?: RefObject<HTMLDivElement>;
}>({
  viewportRef: undefined,
});

export const useNativeScrollAreaContext = () =>
  useContext(nativeScrollAreaContext);

export type NativeScrollAreaProps = {
  children?: React.ReactNode;
  backgroundColor?: string;
  viewportProps?: React.HTMLAttributes<HTMLDivElement>;
  fade?: boolean;
  moreBelowIndicator?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const NativeScrollArea = ({
  children,
  backgroundColor,
  viewportProps,
  fade,
  moreBelowIndicator,
  ...props
}: NativeScrollAreaProps) => {
  const viewport = useRef<HTMLDivElement>(null);

  const topFade = useRef<HTMLDivElement>(null);
  const bottomFade = useRef<HTMLDivElement>(null);
  const moreBelowIndicatorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = viewport.current;

    // We handle these state changes outside of react, for performance
    const handleScroll = () => {
      if (target && topFade.current) {
        topFade.current.style.opacity = target.scrollTop <= 0 ? '0' : '1';
      }

      if (target) {
        const showBottomFade = !(
          target.scrollTop + target.clientHeight >=
          target.scrollHeight - 4
        );

        if (bottomFade.current)
          bottomFade.current.style.opacity = showBottomFade ? '1' : '0';
        if (moreBelowIndicatorRef.current)
          moreBelowIndicatorRef.current.style.opacity = showBottomFade
            ? '1'
            : '0';
      }
    };

    handleScroll();

    const unobserveScrollheight = observeScrollHeight(target, handleScroll);

    if (target) {
      target.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (target) {
        target.removeEventListener('scroll', handleScroll);
        unobserveScrollheight();
      }
    };
  }, [viewport]);

  return (
    <nativeScrollAreaContext.Provider value={{ viewportRef: viewport }}>
      <div
        className={cn('relative h-auto min-h-0 overflow-clip', props.className)}
        style={
          {
            '--background-color': backgroundColor ?? 'white',
          } as CSSProperties
        }
      >
        <div
          className={cn('h-full overflow-auto', viewportProps?.className)}
          ref={viewport}
          tabIndex={0}
        >
          {children}
        </div>
        {fade && (
          <>
            <div
              ref={topFade}
              className="pointer-events-none absolute left-0 top-0 h-12 w-full bg-gradient-to-b from-[var(--background-color)] to-transparent opacity-0 transition duration-75"
            />
            <div
              ref={bottomFade}
              className="pointer-events-none absolute bottom-0 left-0 h-12 w-full bg-gradient-to-t from-[var(--background-color)] to-transparent opacity-0 transition duration-75"
            />
          </>
        )}
        {moreBelowIndicator && (
          <div
            aria-hidden
            {...props}
            className={clsx(
              'pointer-events-none absolute bottom-2 left-1/2 flex -translate-x-1/2 items-center gap-2 rounded-xl p-3 transition-opacity',
              'opacity-0 [background:color-mix(in_srgb,var(--typography-paragraph-textColor)_10%,var(--canvas-backgroundColor))]',
              'whitespace-nowrap',
              props.className
            )}
            ref={moreBelowIndicatorRef}
          >
            <ArrowDown weight="bold" />
            <span className="bits-text-subtitle-2">{moreBelowIndicator}</span>
          </div>
        )}
      </div>
    </nativeScrollAreaContext.Provider>
  );
};
