import { useState } from 'react';
import { CheckCircle, Copy } from '@phosphor-icons/react';

import { PopoverPrimitive } from '..';
import { cn } from './utils/cn';

export interface TeenyTinyCopyButtonProps {
  accessibleName: string;
  value: string;
}

export const TeenyTinyCopyButton = ({
  accessibleName,
  value,
}: TeenyTinyCopyButtonProps) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(value),
      setShowPopover(true),
      setTimeout(() => setShowPopover(false), 1000);
  };

  return (
    <PopoverPrimitive.Root open={showPopover}>
      <PopoverPrimitive.Trigger asChild>
        <button
          type="button"
          aria-label={`Copy ${accessibleName}`}
          onClick={handleClick}
          className={cn(
            'relative',
            'before:absolute before:rounded-full before:bg-ink/10 before:p-2.5 before:transition-all before:duration-75 before:content-[""]',
            'before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:opacity-0',
            'hover:before:opacity-100',
            showPopover && 'before:bg-ink before:opacity-100 before:duration-0'
          )}
        >
          {!showPopover && <Copy className="relative" />}
          {showPopover && <CheckCircle className="relative text-white" />}
        </button>
      </PopoverPrimitive.Trigger>
      <PopoverPrimitive.Content
        className="bits-text-caption pointer-events-none z-[9999] rounded-md bg-ink p-1 px-2 text-white"
        sideOffset={7}
      >
        Copied to clipboard
        <PopoverPrimitive.Arrow />
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Root>
  );
};
