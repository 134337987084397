import * as React from 'react';

import { cn } from './utils/cn';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  onValueChange?: (value: string) => void;
  /**
   * Shows a prefix placeholder in the input.
   * This is useful for custom properties that will be transformed without the users input.
   */
  prefixPlaceholder?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onValueChange, prefixPlaceholder, ...props }, ref) => {
    return (
      <div className="flex h-11 w-full rounded-md border border-fog focus-within:border-ink focus-within:outline-none">
        {prefixPlaceholder && (
          <div
            aria-hidden
            className="bits-text-body-1 pointer-events-none flex h-full items-center justify-center rounded-md rounded-r-none bg-fog p-2 text-ash"
          >
            {prefixPlaceholder}
          </div>
        )}
        <input
          type={type}
          className={cn(
            'color-ink bits-text-body-1 flex h-full w-full bg-transparent px-3 py-2 transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-smoke focus:outline-none disabled:bg-fog disabled:text-ash',
            className
          )}
          ref={ref}
          {...props}
          onChange={(e) => {
            if (onValueChange) {
              onValueChange(e.target.value);
            }
            props.onChange?.(e);
          }}
        />
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
