import { forwardRef, ReactNode } from 'react';

import { Checkbox } from '../checkbox';
import { cn } from '../utils/cn';
import { InputErrorAndHint } from './input-error-and-hint';

export interface FormCheckboxProps {
  label?: ReactNode;
  hint?: string;
  error?: string;
  checked?: boolean | 'indeterminate';
  onCheckedChange?: (checked: boolean) => void;
  disabled?: boolean;
}

export const FormCheckbox = forwardRef<HTMLButtonElement, FormCheckboxProps>(
  ({ label, hint, error, disabled, ...props }: FormCheckboxProps, ref) => {
    return (
      <div>
        <label className="flex gap-2">
          <Checkbox
            {...props}
            ref={ref}
            disabled={disabled}
            className="relative"
          />
          {label && (
            <p
              className={cn(
                'bits-text-body-2 mb-1',
                disabled && 'cursor-not-allowed opacity-50'
              )}
            >
              {label}
            </p>
          )}
        </label>
        <InputErrorAndHint error={error} hint={hint} />
      </div>
    );
  }
);

FormCheckbox.displayName = 'FormCheckbox';
