/**
 * Get the app version based on the hostname
 * @param version - The version of the app
 * @returns The app version
 */
export const getAppVersion = (version: string) => {
  if (typeof window !== 'undefined') {
    if (window.location.hostname === 'localhost') {
      return `${version}-dev`;
    }
    // Example vercel domain: nocode-1d109dk120d-bits-technology.vercel.app
    if (window.location.hostname.includes('vercel.app')) {
      const branch = window.location.hostname
        .replace('-bits-technology', '')
        .replace('.vercel.app', '');
      return `${version}-pre-${branch}`;
    }
    if (window.location.hostname.includes('bits.builders')) {
      return `${version}-sandbox`;
    }
    if (window.location.hostname.includes('bits.beer')) {
      return `${version}-beta`;
    }
  }

  return version;
};
