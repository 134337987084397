import { z } from 'zod';

export const customerSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const createCustomerSchema = z.object({
  name: z.string().min(1),
});

export const createCustomerDocumentPresignedUrlRequestSchema = z.object({
  name: z.string().min(1),
  contentType: z.string().min(1),
  contentLength: z.number().min(1),
});

export const createCustomerDocumentPresignedUrlResponseSchema = z.object({
  url: z.string(),
  uploadHeaders: z.record(z.string(), z.array(z.string())),
  documentId: z.string(),
});
