import { cn } from '../../utils/cn';

export const Spinner = ({ className }: { className?: string }) => (
  <>
    <div
      className={cn(
        'relative h-10 w-10 overflow-hidden rounded-full',
        'animate-spin bg-[conic-gradient(var(--tw-gradient-from),var(--tw-gradient-to))] from-transparent from-25% to-current opacity-70',
        className
      )}
    >
      <span className="absolute inset-px rounded-full bg-theme-canvas-backgroundColor" />
    </div>
  </>
);
