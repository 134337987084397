import * as React from 'react';
import { HTMLProps } from 'react';

import { cn } from './utils/cn';

export type TextareaProps = HTMLProps<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'color-ink bits-text-body-1 flex min-h-[44px] w-full rounded-md border border-fog bg-transparent px-3 py-2 transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-smoke focus-visible:border-ink focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-fog disabled:text-smoke',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
