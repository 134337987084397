import { forwardRef, useEffect, useState } from 'react';
import { FunnelSimple, MagnifyingGlass, X } from '@phosphor-icons/react';

import { cn } from '../utils/cn';

export interface SearchInputProps {
  /**
   * The value of the input.
   */
  value?: string;
  /**
   * The placeholder of the input.
   */
  placeholder?: string;
  /**
   * Callback to be called when the value changes.
   * @param value - The new value.
   */
  onValueChange?: (value: string) => void;
  /**
   * Debounce time in milliseconds.
   */
  debounce?: number;
  /**
   * Additional class name.
   */
  className?: string;
  /**
   * Icon style.
   */
  iconStyle?: 'search' | 'filter';
}

/**
 * Search input component.
 *
 * @example
 * ```tsx
 * <SearchInput value={query} onValueChange={setQuery} />
 * ```
 */
export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      value = '',
      placeholder = 'Search',
      onValueChange,
      debounce = 350,
      iconStyle = 'search',
      className,
    }: SearchInputProps,
    ref
  ) => {
    const [internalValue, setInternalValue] = useState('');

    useEffect(() => {
      setInternalValue(value || '');
    }, [value]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (onValueChange && value !== internalValue) {
          onValueChange(internalValue);
        }
      }, debounce);

      return () => clearTimeout(timeout);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue, value]);

    const Icon = iconStyle === 'filter' ? FunnelSimple : MagnifyingGlass;

    return (
      <div className="relative">
        <input
          type="search"
          placeholder={placeholder}
          className={cn(
            'h-9 w-[170px] rounded-lg border border-fog bg-white p-2 pl-7',
            'placeholder:text-smoke',
            'focus-within:outline-none focus-within:ring-2 focus-within:ring-smoke',
            '[&::-webkit-search-cancel-button]:hidden [&::ms-clear]:hidden',
            className
          )}
          ref={ref}
          value={internalValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (onValueChange) {
                onValueChange(internalValue);
              }
            }
          }}
          onChange={(e) => {
            setInternalValue(e.target.value);
          }}
        />

        <div className="absolute left-2 top-1/2 -translate-y-1/2 text-smoke">
          {internalValue && (
            <button
              className={cn(
                '-ml-1 block rounded-full p-1 text-ink',
                'focus-within:bg-fog focus-within:outline-none focus-within:ring-2 focus-within:ring-smoke hover:bg-fog'
              )}
              aria-label="Clear search input"
              onClick={() => {
                if (onValueChange) {
                  onValueChange('');
                  setInternalValue('');
                }
              }}
            >
              <X className="size-4" />
            </button>
          )}
          {!internalValue && <Icon weight="bold" className="size-4" />}
        </div>
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';
