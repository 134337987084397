import React, { ChangeEvent, forwardRef, useCallback } from 'react';

import { Label } from '../label';
import { Textarea, TextareaProps } from '../textarea';
import { InputErrorAndHint } from './input-error-and-hint';

interface FormTextAreaProps extends TextareaProps {
  label?: string;
  error?: string;
  hint?: string;
  onValueChange?: (value: string) => void;
}

export const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ label, hint, error, onValueChange, ...props }, ref) => {
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        props.onChange?.(event);
        onValueChange?.(event.target.value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.onChange, onValueChange]
    );

    return (
      <div>
        <Label>
          {label && <p className="bits-text-body-2 mb-1">{label}</p>}
          <Textarea {...props} ref={ref} onChange={handleChange} />
        </Label>
        <InputErrorAndHint error={error} hint={hint} />
      </div>
    );
  }
);

FormTextArea.displayName = 'FormTextArea';
