import React, { useEffect, useRef, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import { Checkbox } from './checkbox';
import { ThemedCheckbox } from './customer-theme';
import { Input } from './input';
import { Label } from './label';
import { nl2br } from './nl2br';
import { cn } from './utils/cn';

export type Option = {
  label: string;
  value: string;
};

export type MultiSelectFieldProps = {
  id: string;
  title: string;
  hint?: string;
  defaultPlaceholder?: string;
  options: Option[];
  values: string[];
  dropdown: boolean;
  single: boolean;
  handleCheck: (id: string, checked: boolean | string) => void;
  className?: string;
  error?: string;
};

type MultiSelectDropdownFieldProps = Omit<MultiSelectFieldProps, 'dropdown'> & {
  isChecked: (v: string) => boolean;
  single: boolean;
};
const MultiSelectDropdownField = ({
  id,
  title,
  hint,
  options,
  isChecked,
  handleCheck,
  defaultPlaceholder,
  single,
  className,
  error,
}: MultiSelectDropdownFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [stub, setStub] = useState('');

  const selectedOptions = options.filter((o) => isChecked(o.value));

  const filteredOptions = options.filter((o) =>
    o.label.toLowerCase().includes(stub.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
        inputRef.current?.blur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickInput = () => {
    if (isOpen) {
      setStub('');
      inputRef.current?.blur();
    }
    setIsOpen(!isOpen);
  };

  const handleCheckedChange = (checked: boolean | string, value: string) => {
    handleCheck(value, checked);
    setIsOpen(false);
    setStub('');
  };
  const placeholder =
    selectedOptions.map((v) => v.label).join(', ') || defaultPlaceholder;

  return (
    <div className={cn(className, 'space-y-4')}>
      <div className="bits-text-subtitle-1">{nl2br(title)}</div>
      {hint ? (
        <p className="bits-text-caption mt-2 text-shadow">{hint}</p>
      ) : null}
      <div ref={containerRef} className="relative">
        <div className="relative">
          <Input
            ref={inputRef}
            value={stub}
            onFocus={handleClickInput}
            placeholder={placeholder}
            onChange={(e) => setStub(e.target.value)}
            autoComplete="off"
            className={cn(
              'mt-2 block overflow-hidden text-ellipsis whitespace-nowrap text-ink',
              {
                'placeholder:text-ink': selectedOptions.length,
                'cursor-pointer': !isOpen,
              }
            )}
            autoCapitalize="off"
            autoCorrect="off"
            type="text"
            data-1p-ignore
            data-lpignore
          />
          <CaretDown
            className={cn(
              'pointer-events-none absolute right-[5%] top-[50%] translate-x-[0%] translate-y-[-50%] transform transition-transform',
              { 'rotate-180': isOpen }
            )}
          />
        </div>
        {isOpen && (
          <div
            id={id}
            className="h-50 absolute z-50 max-h-[130px] w-full space-y-3 overflow-y-scroll rounded-md border bg-background py-2 shadow-lg lg:max-h-[180px]"
          >
            {filteredOptions.map(({ value, label }) => (
              <div
                key={`${id}-${value}`}
                className="flex flex-row items-center gap-3 px-2 hover:cursor-pointer"
              >
                <Checkbox
                  id={`${id}-${value}`}
                  checked={isChecked(value)}
                  className={cn(
                    'border-fog data-[state=checked]:bg-transparent data-[state=checked]:font-bold data-[state=checked]:text-ink',
                    {
                      'border-none': single,
                    }
                  )}
                  onCheckedChange={(checked) =>
                    handleCheckedChange(checked, value)
                  }
                />
                <Label
                  className="w-full hover:cursor-pointer"
                  htmlFor={`${id}-${value}`}
                >
                  {label}
                </Label>
              </div>
            ))}
          </div>
        )}
        {error ? <p className="text-grapefruit">{error}</p> : null}
      </div>
    </div>
  );
};

const MultiSelectField = ({
  id,
  title,
  hint,
  defaultPlaceholder,
  options,
  values,
  handleCheck,
  dropdown,
  single,
  className,
  error,
}: MultiSelectFieldProps) => {
  const isChecked = (v: string) => !!values.find((a) => a === v);

  if (dropdown) {
    return (
      <MultiSelectDropdownField
        id={id}
        className={className}
        title={title}
        options={options}
        hint={hint}
        defaultPlaceholder={defaultPlaceholder}
        values={values}
        handleCheck={handleCheck}
        isChecked={isChecked}
        error={error}
        single={single}
      />
    );
  }

  return (
    <div className={cn('grid gap-4', className)}>
      <div className="bits-text-subtitle-1">{nl2br(title)}</div>
      {hint ? <p className="theme-ty bits-text-caption">{hint}</p> : null}
      {options.map(({ value, label }) => (
        <ThemedCheckbox
          value={value}
          label={label}
          onCheckedChange={(checked) => handleCheck(value, checked)}
          key={value}
        />
      ))}
      {error ? <p className="text-grapefruit">{error}</p> : null}
    </div>
  );
};

export { MultiSelectField };
